.classroom__maintenance {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 95%;  
}

.classroom__maintenance--container {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    background-color: white;
    width: 80%;
    padding: 20px 0;
    border-radius: 20px;
    border: 2px solid #192b80;
}

.classroom__maintenance--container > p {
    text-align: center;
    font-size: 36px;
    width: 800px;
}

.classroom__maintenance--bg--wrapper {
    position: absolute;
    width: 168vh;
    height: 102vh;
    z-index: -1;
}

.classroom__maintenance--bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}