#admin {
    background-color: lightgray;
}

.admin__login {
    background-color: white;
    width: 30%;
    min-width: 200px;
    height: 200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.admin__container {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
}

.admin__container > h2 {
    text-align: center;
    margin-top: 20px;
}

.admin__menus {
    margin: 0 auto;
}

.admin__menus > button {
    width: 300px;
    margin-top: 12px    ;
    padding: 8px 26px;
}

.admin__content {
    background-color: lightgray;
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 80px;
}

.admin__content > h1 {
    text-align: center;
    color: #192b80;
    margin-top: 20px;
}

.admin__users--submit-btn {
    font-size: 32px;
    padding: 8px 24px;
    font-weight: 700;
    margin-top: 40px;
}