.classes__edit--backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.56);
    display: flex;
    align-items: center;
    justify-content: center;
}

.classes__edit--backdrop--container {
    width: 500px;
    background-color: white;
    border: 1.5px solid #192b80;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.classes__edit--backdrop--container > label {
    margin-top: 12px;
    display: flex;
    align-items: center;
}

.classes__edit--backdrop--container > label > input {
    width: 20px;
    height: 20px;
    margin-left: 10px;
}

.classes__edit--backdrop--btn {
    width: 100px;
    padding: 2px 0;
    font-size: 18px;
    font-weight: 600;
    margin: 0 auto;
    margin-top: 20px;
}

.classes__edit--backdrop--container > span {
    display: flex;
    align-items: center;
    transform: translateY(-16px);
    font-weight: 700;
    cursor: pointer;
}

.classes__edit--backdrop--close-icon {
    font-size: 20px !important;
    color: black;
}

.classes__edit--add--checkbox {
    width: 18px;
    height: 18px;
}
