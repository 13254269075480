/*

For Home 

*/

.classroom__content--home {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    color: white;
    overflow: hidden;
}

.classroom__content--home::-webkit-scrollbar {
    width: 1px;
    background-color: white;
}

.classroom__home--bg-wrapper {
    position: absolute;
    z-index: -1;
    filter: brightness(0.24) opacity(0.82);
    height: 100%;
    width: calc(100% - 250px);
    overflow: hidden;
}

.classroom__home--bg {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.classroom__content--home > h2 { 
    text-align: center;
    margin-top: 40px;
    font-size: 36px;
    width: 60%;
}

.classroom__content--home > p { 
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
    width: 80%;
}

/* 

For actual Content

*/

.classroom__content--container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.classroom__content--container > h2 {
    text-align: center;
    margin-top: 40px;
    font-size: 36px;
    width: 60%;
}

.classroom__content--bg {
    width: calc(100% - 250px);
    height: 99%;
    filter: blur(2px);
    opacity: 0.65;
    position: absolute;
    transform: translateX(-20px);
    z-index: -2;
}

/*

For books 

*/


.classroom__book{
    width: 240px;
}

.classroom__books--grid {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 240px 240px 240px 240px;
    place-items: center;
    gap: 20px;
}