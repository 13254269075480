#header {
  position: relative;
}

.header__container {
  height: calc(100vh - 100px);
  width: 100%;
}

.header__bg--wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  filter: brightness(0.32) opacity(0.82);
  z-index: -1;
}

.header__bg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header__left {
  width: 60%;
  padding-right: 80px;
  margin-left: 20px;
}

.header__right {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header__description {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.header__logo {
  width: 80%;
  height: 80%;
}

.header__title {
  color: white;
  font-size: 40px;
  margin-bottom: 16px;
}

.header__sub-title {
  color: white;
}

.header__span {
  color: white;
  background-color: #192b90;
  padding: 0 4px;
}

.header__span--2 {
  display: none;
}

.header__bottom-shape--wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 88px;
}

.header__shape {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

/*

ABOUT ME

*/

#aboutme {
  background-color: white;
  padding-top: 20px;
  position: relative;
  padding-bottom: 0px;
}

.aboutme__container {
  padding: 0 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.aboutme__container--text {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutme__left {
  width: 40%;
  margin: 0 auto;
}

.aboutme__right {
  width: 50%;
  display: flex;
  flex-direction: column;
}

.aboutme__title {
  font-size: 32px;
  text-decoration: underline;
  text-decoration-color: #192b90;
  text-decoration-thickness: 10%;
  margin: 0 auto 32px auto;
}

.aboutme__img--wrapper {
  width: 100%;
}

.aboutme__img {
  width: 100%;
  height: 100%;
  border-radius: 12px;
  box-shadow: 2px 4px 10px 1px rgba(25, 43, 144, 0.55);
}

.aboutme__img--wrapper-2 {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50%;
}

.aboutme__img--2 {
  width: 50%;
  height: 80%;
  object-fit: contain;
}

.aboutme__slide--img--wrapper {
  margin: 10px auto;
  width: 80%;
  height: 120px;
  overflow: hidden;
  box-shadow: 2px 2px 10px 2px #192b9067;
}

.aboutme__slide--img {
  width: 150%;
  height: 150%;
  object-fit: cover;
  object-position: 115%;
}

.aboutme1__shape--wrapper {
  width: 100%;
  margin-top: 10px;
  position: relative;
  min-height: 160px;
  bottom: -1px;
}

/*

Aboutme 2

*/

#aboutme__2 {
  background-color: #249cb8;
  color: white;
  position: relative;
}

.aboutme__slides {
  margin: 0 20px;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  padding-bottom: 40px;
}

.aboutme__slide {
  margin: 20px 0;
  margin-top: 40px;
}

.aboutme__slide {
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.aboutme__slide--1 {
  flex-direction: row-reverse;
  animation: fadeLeft 300ms ease;
}

.aboutme__slide--2 {
  margin-left: auto;
  animation: fadeRight 300ms ease;
}

@keyframes fadeLeft {
  0% {
    opacity: 0;
    transform: translateX(200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeRight {
  0% {
    opacity: 0;
    transform: translateX(-200px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.aboutme__slide--text {
  font-size: 20px;
  width: 50%;
  margin-left: 40px;
  margin-right: 40px;
  text-align: left;
}

.slides__title {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.slide__title {
  cursor: pointer;
  background-color: #192b90;
  padding: 12px 60px;
  border-radius: 16px;
  font-size: 24px;
  position: relative;
  transition: all 200ms ease-in-out;
}

.slide__title--inactive:hover {
  background-color: white;
  color: black;
}

.slide__title--inactive:hover::after {
  background-color: white;
  color: black;
  background-color: black;
}

.slide__title--inactive {
  background-color: #249cb8;
}

.slide__title--inactive::after {
  content: "";
  height: 3px;
  background-color: white;
  width: 60%;
  position: absolute;
  top: 44px;
  left: 50%;
  transform: translateX(-50%);
  transition: background-color 200ms ease-in-out;
}

.shape--aboutme2 {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  filter: brightness(0) invert(1);
  z-index: 0;
}

.shape--inverted {
  transform: scaleX(-1);
  right: auto;
}

/* 

SECTION COURSES 

*/

#courses {
  background-color: white;
  position: relative;
  overflow: hidden;
}

.courses__container {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 60px 0;
  margin-bottom: 80px;
  z-index: 2;
  position: relative;
}

.courses__title {
  font-size: 32px;
  text-decoration: underline;
  text-decoration-color: #192b90;
  text-decoration-thickness: 10%;
  margin-bottom: 20px;
}

.course__1,
.course__2 {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.courses__titles,
.courses__btns {
  position: relative;
  display: flex;
  justify-content: space-around;
  margin: 0 auto;
  left: 0;
  width: 100%;
  margin: 20px 0;
}

.courses__information--btn {
  padding: 6px 20px;
  border-radius: 4px;
  border: none;
  background-color: #192b90;
  color: white;
  cursor: pointer;
  transition: all 120ms ease;
  letter-spacing: normal;
  font-size: 20px;
  font-weight: 700;
  margin-top: 24px;
  transition: background-color 200ms ease;
}

.courses__information--btn:hover {
  background-color: #25d366;
}

.courses__img--title-1,
.courses__img--title-2 {
  position: relative;
  text-align: center;
  width: 80%;
  margin-bottom: 20px;
}
.courses__imgs--container {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.courses__img--wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  margin: 12px 40px;
  border-radius: 16px;
  height: 320px;
  z-index: 2;
}

.courses__img {
  width: 105%;
  height: 105%;
  transition: filter 200ms ease;
}

.img__backdrop {
  width: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(150%);
  opacity: 0;
  transition: transform 300ms ease, opacity 80ms ease;
  padding: 20px;
  z-index: 1;
}

.courses__img--wrapper:hover .img__backdrop {
  opacity: 1;
  transform: translateX(-50%) translateY(-50%);
}
.courses__img--wrapper:hover .courses__img {
  filter: blur(4px);
}

.courses__img--wrapper:hover .course__onSale {
  filter: blur(4px);
}

.img__backdrop--text {
  font-size: 20px;
  margin-bottom: 12px;
}

.img__backdrop--btn {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: all 120ms ease;
  letter-spacing: normal;
}

.img__backdrop--btn:hover {
  background-color: #192b90;
}

.course__onSale {
  position: absolute;
  background-color: #192b90;
  padding: 4px;
  width: 260px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: gold;
  right: -64px;
  top: 40px;
  rotate: 45deg;
  z-index: 0;
  transition: filter 200ms ease;
}

.courses__shape--wrapper {
  width: 100%;
  margin-top: 10px;
  min-height: 80px;
  position: relative;
}

.courses__shape {
  position: absolute;
  bottom: 0;
  width: 100%;
  filter: invert(1) opacity(0.5);
  z-index: 2;
}

.shape__courses--2 {
  filter: none;
  bottom: 50%;
  margin-bottom: 1.4%;
  z-index: 0;
  height: auto;
}

.shape-top {
  top: 0;
  left: 50%;
}

/* 

SERVICES

*/
#features {
  color: white;
  background-color: gray;
}

.features__container {
  padding: 0 40px 40px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.features__title {
  font-size: 32px;
  text-decoration: underline;
  text-decoration-color: #192b90;
  text-decoration-thickness: 10%;
  margin-bottom: 12px;
}

.features {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.feature {
  width: 30%;
  margin: 32px 12px;
}

.feature__description {
  height: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  text-align: center;
}

.feature__title {
  margin-top: 8px;
}

.feature__text {
  margin-top: 4px;
  font-size: 18px;
}

.MuiSvgIcon-root {
  color: #192b90;
  font-size: 52px !important;
}

@media (min-width: 1600px) {
}

@media (max-width: 1024px) {
  .header__description {
    padding: 0 24px;
  }
  .container {
    padding: 0;
  }

  .header__title {
    font-size: 32px;
  }

  .header__sub-title {
    font-size: 16px;
  }

  .header__left {
    margin-left: 40px;
  }

  .header__logo {
    margin-right: 20px;
  }

  .courses__shape--wrapper {
    min-height: 60px;
  }

  .courses__img--title-1,
  .courses__img--title-2 {
    width: 90%;
    font-size: 22px;
  }
}

@media (max-width: 868px) {
  .container {
    padding: 0;
  }

  .header__logo {
    margin-right: 0;
  }

  .header__title {
    font-size: 24px;
  }

  .header__sub-title {
    font-size: 16px;
  }

  .header__left {
    margin-left: 40px;
  }

  .courses__shape--wrapper {
    min-height: 48px;
  }
}

@media (max-width: 768px) {
  #aboutme {
    padding: 0;
  }
  .header__description {
    flex-direction: column-reverse;
    justify-content: center;
    height: 88%;
    text-align: center;
  }

  .header__left {
    width: 100%;
    margin: 0;
    padding: 0 30px;
    margin-bottom: 20px;
  }

  .header__right {
    width: 70%;
  }

  .aboutme__title {
    margin-bottom: 0;
  }

  .aboutme__container {
    flex-direction: column;
    padding: 0 20px;
  }

  .aboutme__container--text {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    top: 20px;
  }

  .aboutme__left {
    width: 60%;
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .aboutme__right {
    width: 100%;
  }

  .aboutme__right > p {
    width: 80%;
    margin: 0 auto;
  }

  .aboutme__img--wrapper-2 {
    height: 30%;
    width: 80%;
    margin: 20px auto -40px auto;
  }

  .aboutme__container {
    padding-bottom: 100px;
  }

  .slide__title {
    padding: 8px 28px;
    font-size: 20px;
  }

  .slide__title--inactive::after {
    top: 36px;
  }

  .aboutme__slide {
    flex-direction: column;
    width: 100%;
  }

  .aboutme__slide--text {
    margin: 0;
    margin-bottom: 20px;
    width: 100%;
    text-align: center;
  }

  .courses__title {
    margin-bottom: 20px;
  }

  .courses__imgs--container {
    flex-direction: column;
  }
  .course__1,
  .course__2 {
    width: 100%;
  }

  .course__1 {
    margin-bottom: 40px;
  }

  .courses__img {
    object-fit: cover;
  }

  .courses__img--wrapper:first-of-type {
    margin-bottom: 20px;
  }

  .courses__shape--wrapper {
    min-height: 40px;
  }

  .shape__courses--2 {
    bottom: 64%;
  }

  .img__backdrop {
    width: 80%;
  }

  .img__backdrop--text {
    font-size: 16px;
  }

  .feature {
    width: 40%;
  }

  .features {
    justify-content: space-around;
  }

  .feature__text {
    margin-top: 4px;
    font-size: 16px;
  }
}

@media (max-width: 568px) {
  .aboutme__slide {
    width: 100%;
    margin-bottom: 20px;
    height: 100%;
  }

  .courses__shape--wrapper {
    min-height: 32px;
  }
}

@media (max-width: 480px) {
  .header__title {
    font-size: 20px;
  }

  .header__logo {
    margin-bottom: 20px;
  }

  .header__right {
    width: 80%;
  }

  .aboutme__title {
    font-size: 32px;
  }

  .aboutme__img--2 {
    width: 200px;
  }

  .aboutme__left {
    width: 100%;
    padding-bottom: 0;
  }

  .slide__title {
    padding: 8px 16px;
    font-size: 16px;
  }

  .slide__title--inactive::after {
    width: 80%;
  }

  .courses__title,
  .features__title {
    font-size: 24px;
  }

  .courses__img--wrapper {
    padding: 0;
    margin: 0 10px;
  }
  .feature {
    width: 100%;
    margin: 10px 20px;
  }

  .courses__shape--wrapper {
    min-height: 24px;
  }
}

@media (max-width: 385px) {
  .header__span {
    display: none;
  }

  .header__span--2 {
    display: block;
    background-color: #192b90;
    margin: 0 40px;
    padding: 4px 0;
    margin-top: 8px;
  }
}

@media (max-width: 350px) {
  .header__span--2 {
    display: block;
    background-color: #192b90;
    margin: 0 20px;
    padding: 4px 0;
    margin-top: 8px;
  }

  .slide__title {
    padding: 8 8px;
    font-size: 14px;
  }
  .slide__title--inactive::after {
    top: 28px;
  }

  .aboutme__slide--text {
    font-size: 18px;
  }
}
