#footer {
  background-color: black;
  text-align: center;
}

.footer__logo {
  width: 150px;
  height: 150px;
  margin-right: 60px;
}

.footer__container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 80px;
}

.footer__description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.footer__socials {
  width: 80%;
  display: flex;
  justify-content: space-around;
  margin-bottom: 20px;
}

.footer__icon {
  cursor: pointer;
  color: white !important;
  border: 2px solid #192b90;
  padding: 8px;
  border-radius: 50%;
}

.footer__icon:hover {
  color: black !important;
  background-color: white;
}

.footer__links {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.footer__link {
  position: relative;
  margin: 0 12px;
  color: white;
  cursor: pointer;
}

.footer__link::after {
  content: "";
  border-bottom: 2px solid #192b90;
  position: absolute;
  width: 0;
  right: 0;
  bottom: -2px;
  opacity: 1;
  transition: all 300ms ease;
}

.footer__link:hover::after {
  left: 0;
  width: 100%;
}

.footer__copyright {
  padding: 24px 0;
}

.footer__text {
  color: white;
  margin-top: 15px;
  font-size: 14px;
}

@media (max-width: 568px) {
  #footer {
    padding: 0 20px;
  }
  .footer__logo {
    width: 120px;
    height: 120px;
    margin-right: 20px;
  }

  .footer__socials {
    margin: 8px 0;
  }

  .footer__links {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .footer__container {
    flex-direction: column;
  }
  .footer__logo {
    margin: 0;
    margin-bottom: 16px;
  }
}
