.class__downloads {
    margin-top: 40px;
    width: 80%;
    display: flex;
    justify-content: space-around;
}

.class__download {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px;
    width: 360px;
    background-color: white;
    outline: 2px solid #192b80;
    border-radius: 8px;
}

.class__download:hover {
    cursor: pointer;
    background-color: lightgray;
    box-shadow: 2px 4px 2px 2px rgba(0, 0, 0, 0.24);
    transform: translate(-4px, -4px);
}

.class__download > p {
    margin-left: 16px;
    font-size: 20px;
    font-weight: 600;
}