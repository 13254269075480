#register {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register__img--wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.register__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.32);
}

.register__container {
    padding: 20px 32px;
    background-color: white;
    position: absolute;
}

.register__text {
    margin: 16px 0;
}

.register__btn {
    padding: 4px 12px;
    background-color: black;
    color: white;
    margin-top: 20px;
    cursor: pointer;
}

.register__btn:hover {
    background-color: gold;
    color: black;
}

.register__text--top {
    margin-top: 16px;
}

.register__text--bottom {
    font-size: 14px;
    margin-top: 16px;
}

.register__link{
    color: black;
    font-weight: 700;
}

.register__link:hover {
    color: gold;
}

.register__btn--google {
    margin: 12px auto;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    border: none;
    border-radius: 16px;
    transition: all 120ms ease;
}

.register__btn--google:hover {
    background-color: white;
    outline: 1.5px solid #192b80;
}