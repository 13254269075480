#login {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login__img--wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.login__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.32);
}

.login__container {
    padding: 20px 32px;
    background-color: white;
    position: absolute;
}

.login__return {
    cursor: pointer;
    margin-bottom: 12px;
}

.login__return--anchor {
    display: flex;
    align-items: center;
    justify-content: left;
}

.login__return--text {
    font-weight: 400;
}

.login__return--anchor:hover .login__return--text {
    font-weight: 600;
}

.login__return--anchor:hover .login__return-icon {
    color: gold;
}

.login__return-icon {
    font-size: 32px !important;
    color: black;
}


.login__text {
    margin: 12px 0;
}


.login__btn {
    padding: 4px 12px;
    background-color: black;
    color: white;
    margin-top: 20px;
    cursor: pointer;
}

.login__btn:hover {
    background-color: gold;
    color: black;
}

.login__text--bottom {
    margin-top: 16px;
}

.login__link{
    color: black;
    font-weight: 700;
}

.login__link:hover {
    color: gold;
}

.input__form {
    padding: 4px 8px;
    margin-top: 8px;
    outline: 1.5px solid #192b90;
    width: 100%;
}

.input__form:focus {
    outline: 2px solid #192b90;
}

form > label {
    color: #192b90;
    font-weight: 600;
}