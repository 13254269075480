#popUp {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.62);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
}

.popUp__container {
  display: flex;
  flex-direction: column;
  background-color: white;
  border-radius: 32px;
  width: 60%;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 24px 32px;
}

.popUp__container > h2 {
  text-align: center;
  font-size: 42px;
  color: #192b90;
}

.popUp__container > h3 {
  font-size: 24px;
  color: gray;
  text-align: center;
}

.popUp__img--wrapper {
  width: 40%;
  position: relative;
  overflow: hidden;
  border-radius: 32px;
}

.popUp__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.popUp__onSale {
  position: absolute;
  background-color: #192b90;
  padding: 4px;
  width: 260px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  color: gold;
  right: -64px;
  top: 40px;
  rotate: 45deg;
  z-index: 0;
}

.popUp__close-icon {
  color: black;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 32px !important;
  transition: all 200ms ease !important;
}

.close__text {
  right: 52px;
  font-size: 20px !important;
}

.popUp__img-description {
  display: flex;
  justify-content: space-around;
  margin: 24px 0;
}

.popUp__description {
  width: 50%;
  padding: 8px 12px;
  background-color: #192b90;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

.popUp__description--border {
    position: absolute;
    height: 95%;
    width: 95%;
    content: "";
    border: #249cb8 2px dashed;
    background: none;
}

.popUp__description > h1 {
  font-size: 28px;
  text-align: center;
}

.popUp__description > h2 {
  font-size: 24px;
  text-align: center;
}

.popUp__description > h3 {
  font-size: 40px;
}

.close--popUp {
  position: absolute;
  top: 0;
  right: 0;
  transition: transform 200ms ease-in-out;
}

.close--popUp:hover {
  transform: scale(1.07);
}

.popUp__btn {
  cursor: pointer;
  color: black;
  background-color: white;
  padding: 4px 12px;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out, color 200ms ease;
  font-weight: 600;
  margin: 0 auto;
  font-size: 28px;
  font-weight: 700;
  z-index: 100;
}

.popUp__btn:hover {
  color: white;
  background-color: #25d366;
}

@media (max-width:1600px) {
  .popUp__container {
    width: 75%;
  }
}

@media (max-width: 1024px) {
  .popUp__container > h2 {
    font-size: 28px;
  }

  .popUp__container > h3 {
    font-size: 20px;
  }

  .popUp__container {
    width: 90%;
  }
}

@media (max-width: 769px) {
    .popUp__container > h2 {
        margin-top: 12px;
    }
    .popUp__description > h1 {
        margin-bottom: 8px;
    }
  .popUp__img-description {
    flex-direction: column;
  }

  .popUp__img--wrapper {
    width: 45%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .popUp__description {
    width: 100%;
    padding: 24px 16px;
  }

  .popUp__btn {
    width: 280px;
    margin-bottom: 8px;
  }
}

@media (max-width: 500px) {

    .popUp__container > h2 {
        font-size: 20px;
    }
    .popUp__container > h3 {
        font-size: 16px;
    }
  .popUp__img--wrapper {
    width: 60%;
  }

  .popUp__img-description {
    margin: 0;
  }

  .close__text {
    display: none;
  }

  .popUp__btn {
    width: auto;
    font-size: 24px;
    margin-top: 16px;
  }

  .popUp__img--wrapper {
    margin: 8px auto;
  }

  .popUp__description > h1 {
    font-size: 16px;
  }

  .popUp__description > h2{ 
    font-size: 24px;
  }

  .popUp__description > h3 > span {
    font-size: 36px;
  }
}
