.admin__classes--add-btn {
    padding: 4px 24px;
    font-size: 20px;
    color: #192b80;
    margin-top: 20px;
    font-weight: 600;
    transform: translateX(-360px);
}

.admin__classes--list {
 margin-top: 24px;
}

.admin__classes--list-top {
    display: flex;
}

.admin__classes--list-title {
    border: 2px solid #192b80;
    padding: 8px 0;
    text-align: center;
    background-color: white;
    font-weight: 700;
}

.admin__classes--list-1 {
    width: 80px;
    border-right: none;
}
.admin__classes--list-2 {
    width: 400px;
    border-right: none;
}
.admin__classes--list-3 {
    width: 460px;
}

.admin__classes--list-content {
    background-color: white;
}

.admin__list--class {
    display: flex;
    padding: 8px 0;
    border-bottom: 2px solid #192b80;
}

.admin__list--class--paragraph1,
.admin__list--class--paragraph2,
.admin__list--class--checkboxes {
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin__list--class--paragraph1 {
    width: 80px;
}

.admin__list--class--paragraph2 {
    width: 400px;
    padding: 0 12px;
}

.admin__list--class--checkboxes {
    width: 460px;
}

.admin__list--class--checkboxes > span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    cursor: pointer;
}

.admin__list--wrench-icon {
    font-size: 22px !important;
    margin-left: 12px;
    color: black;
}