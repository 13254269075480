#dashboard {
  position: relative;
  height: 100vh;
}

/*

Backdrop

*/

.dashboard__backdrop {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.62);
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  display: none;
  align-items: center;
  justify-content: center;
}

.dashboard__backdrop--active {
  display: flex;
}

.dashboard__backdrop--container {
  background-color: white;
  border: 2px solid #192b90;
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.dashboard__backdrop--container > p {
  margin-bottom: 12px;
  font-weight: 600;
}

.dashboard__backdrop--container > input {
  padding: 4px 8px;
}

.dashboard__backdrop--container > input:focus {
  outline: #192b90 solid 2px;
}
.dashboard__backdrop--btn {
  width: 60px;
  padding: 2px 0 !important;
  margin-top: 12px !important;
  font-size: 14px !important;
}

/*

Backdrop

*/

.dashboard__bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  filter: brightness(0.32) opacity(0.86);
}

.dashboard__container {
  width: 70%;
  height: 92%;
  border-radius: 20px;
  border: 2px solid #192b90;
  box-shadow: 0 6px 10px 8px rgba(0, 0, 0, 0.5);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  padding: 20px 40px;
  text-align: center;
}

.dashboard__user-information {
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.img__container {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.user__img--wrapper {
  background-color: white;
  width: 240px;
  height: 240px;
  position: relative;
  cursor: pointer;
  position: relative;
  border-radius: 999px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 3.5px solid #192b90;
  overflow: hidden;
}

.user__img--wrapper::after {
  position: absolute;
  content: "Editar";
  color: white;
  background-color: #192b90;
  font-weight: 600;
  bottom: 0;
  width: 100%;
  height: 24px;
  left: 0;
  text-align: center;
  padding-top: 2px;
}

.user__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user__img--btn {
  position: absolute;
  bottom: 0;
  right: 0;
}

.dashboard__input {
  display: none;
}

.dashboard__user-description {
  width: 60%;
  height: 100%;
  padding: 20px 0;
  padding-left: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.user__edit {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: right;
}

.user__edit > span {
  cursor: pointer;
}

.user__edit--icon {
  font-size: 24px !important;
  color: black;
}

.dashboard__btn {
  padding: 4px 16px;
  background-color: #192b90;
  color: white;
  font-weight: 700;
  transition: 120ms ease-in;
  margin-top: 20px;
  font-size: 18px;
  width: 180px;
}

.dashboard__btn:hover {
  background-color: white;
  color: black;
}

.dashboard__container > h3 {
  font-size: 28px;
  margin-bottom: 16px;
}

.dashboard__container > h2 {
  font-size: 32px;
  color: #192b90;
  text-decoration: underline;
}

.dashboard__courses {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.dashboard__course {
  display: flex;
  flex-direction: column;
  width: 260px;
}

.dashboard__course--img {
  width: 100%;
}

.dashboard__course--btn {
  width: 100%;
  background-color: #192b90;
  color: white;
  padding: 4px 0;
  transition: all 120ms ease;
}

.dashboard__course--btn:hover {
  color: black;
  background-color: white;
}

@media (max-width: 1024px) {
    .user__img--wrapper {
        width: 160px;
        height: 160px;
    }
}

@media (max-width: 726px) {
    .user__img--wrapper {
        width: 120px;
        height: 120px;
    }

    .dashboard__user-description {
        padding-left: 0;
    }
}