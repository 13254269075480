.admin__users--list {
    display: flex;
    flex-direction: column;
    width: 1200px;
    margin-top: 20px;
    background-color: white;
}

.admin__users--list--top {
    display: flex;
}

.admin__users--list--top1,
.admin__users--list--top2,
.admin__users--list--top3 {
    background-color: lightgray;
    color: #192b80;
    border: 2px solid #192b80;
    font-size: 24px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}
.admin__users--list--top1,
.admin__users--list--top2 {
    width: 35%;
}

.admin__users--list--top3 {
    width: 30%
}

.admin__list--user {
    display: flex;
    height: 60px;
    border-top: 1.5px solid #192b80;
    border-bottom: 1.5px solid #192b80;
}

.admin__list--user > p {
    width: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin__list--user > div {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin__list--user--checkboxes > p {
    text-align: right;
    display: flex;
    justify-content: right;
    cursor: pointer;
    font-weight: 700;
}

.admin__list--user--checkboxes > div {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-left: 20px;
    margin-right: 20px;
}

/*

Backdrop

*/

.user__edit--backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.56);
    display: flex;
    align-items: center;
    justify-content: center;
}

.user__edit--container {
    width: 500px;
    background-color: white;
    border: 1.5px solid #192b80;
    padding: 40px;
    display: flex;
    flex-direction: column;
}

.users__list--checkbox {
    width: 15px;
    height: 15px;
    accent-color: #192b80;
}
