#courses_2 {
  position: relative;
}

.course__container {
  display: flex;
  background-color: white;
  flex-wrap: wrap;
}

.course__img--wrapper {
  flex: 0.4;
  padding: 2%;
}

.course__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 32px;
}

.course__description {
  flex: 0.6;
  padding: 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.courses__bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  object-fit: cover;
  filter: opacity(0.5) brightness(0.5);
}

.course__title {
  color: #192b90;
  text-align: center;
  margin-bottom: 20px;
  height: 100px;
  background-color: #09082d;
  width: 100%;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 30px;
}

.course__title > span {
  color: #249cb8;
}

.course__title::after {
  content: "con AutoCAD y Pizarra Virtual";
  position: absolute;
  bottom: 18px;
  color: gray;
}

.course__books {
  margin-top: 20px;
  display: flex;
  justify-content: space-around;
}

.course__book {
  width: 22%;
  height: 180px;
}

.course__book--img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.course__btn {
  margin-top: 16px;
  cursor: pointer;
  color: white;
  background-color: #192b90;
  padding: 4px 24px;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out, color 200ms ease;
  font-weight: 600;
  font-size: 20px;
}

.course__btn:hover {
  background-color: #09082d;
  color: white;
}

.course__subjects {
  width: 100%;
}

.close__btn {
  width: 280px;
  margin: 0 auto;
  margin-top: 12px;
}

.course__subjects--drop {
  padding: 20px 80px;
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  position: relative;
}

.subject__title {
  text-align: left;
  background-color: #192b90;
  color: white;
  font-size: 32px;
  position: absolute;
  top: 32px;
  left: 0;
  padding: 12px 32px 12px 100px;
  width: 50%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  opacity: 0;
  transition: opacity 300ms ease;
}

.subject__title--active {
  opacity: 1;
}

.course__list {
  list-style-image: url("../../assets/Rombo.svg");
}

.course__item {
  color: black;
}

.course__item > h4 {
  position: relative;
  top: -16px;
}

.course__item > p {
  position: relative;
  top: -16px;
}

.course__item--title {
  color: #192b90;
  font-weight: 700;
}

.courses__payment-schedule {
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  padding-bottom: 40px;
}

.courses__payment--container {
  position: relative;
  padding-left: 20px;
  width: 50%;
}

.courses__payment--container > h3 {
  text-align: left;
  background-color: #192b90;
  color: white;
  font-size: 32px;
  position: absolute;
  left: 0;
  padding: 12px 32px 12px 100px;
  width: 100%;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.courses__payment--container > h2 {
  margin-top: 60px;
}

.courses__payment-methods--wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 80px;
  margin-left: 80px;
  height: 440px;
}

.payment__method {
  display: flex;
  align-items: center;
  justify-content: left;
}

.payment__logo--wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 100%;
  margin-right: 4px;
}

.payment__logo {
  width: 54px;
  height: 54px;
  object-fit: cover;
}

.paypal--wrapper {
  width: 52px;
  height: 52px;
}

.paypal__logo {
  object-fit: contain;
  width: 80%;
  height: 100%;
}

.western__logo {
  width: 52px;
  height: 52px;
  object-fit: contain;
  margin-right: 4px;
}

.payment__method > p {
  font-size: 20px;
  margin-left: 12px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 4px solid #192b90;
  padding-left: 8px;
}

.courses__schedule {
  width: 44%;
  height: 400px;
  background-color: #249cb8;
  color: white;
  padding: 12px 24px 20px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.courses__schedule--descriptive {
  height: 320px;
}

.courses__schedule--descriptive .courses__zoom--wrapper{
  width: 100px;
  height: 100px;
}

.courses__schedule > h3 {
  font-size: 32px;
  text-align: center;
  margin-top: 12px;
}

.courses__schedule > h3 > span {
  position: relative;
}

.courses__schedule > h3 > span::after {
  content: "";
  position: absolute;
  left: -8px;
  right: -8px;
  bottom: 1px;
  height: 2.5px;
  background-color: white;
}

.courses__zoom--wrapper {
  width: 120px;
  height: 120px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2d8cff;
  border-radius: 100%;
  border: white 1px solid;
}

.zoom__logo {
  width: 80%;
  height: 80%;
  object-fit: contain;
}

.courses__schedule--container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 80%;
}

.courses__schedule--container > ul {
  transform: translate(-8px);
}

.schedule__item > span:first-of-type {
  color: #192b90;
  font-weight: 700;
  font-size: 20px;
  position: relative;
  top: -20px;
}

.span__hour {
  color: white;
  font-size: 17px;
  font-weight: 600;
  position: relative;
  top: -20px;
}

.schedule__item {
  margin-top: 12px;
  list-style-image: url("../../assets/Rombo.svg");
  color: #09082d;
}

.courses__schedule > p {
  font-size: 22px;
  padding-left: 40px;
  font-weight: 600;
  margin-bottom: 12px;
}

.span__blue {
  color: #09082d;
  font-weight: 700;
}

.course__container--schedule-price {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.courses__price-1 {
  margin: 20px auto;
  background-color: #192b90;
  color: white;
  width: 100%;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  text-align: center;
}

.courses__price-1::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 82%;
  border-top: 2px dashed #249cb8;
  border-bottom: 2px dashed #249cb8;
}

.courses__price-1 > h3 > span {
  color: #00d0ff;
}

.courses__price-1 > p {
  font-size: 48px;
  font-weight: 700;
}
.courses__price-1 > p > span {
  color: gold;
}

.courses__price-1 > span {
  font-size: 20px;
  color: lightgray;
}

/*

  STEPS TO APPLY

  */

.courses__steps--container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.courses__steps--container > h3 {
  color: #192b90;
  font-weight: 800;
  font-size: 40px;
  letter-spacing: -1.5px;
}

.courses__steps--wrapper {
  background-color: white;
  border: 2px solid #192b90;
  margin: 8px 40px 60px 40px;
  border-radius: 42px;
  padding: 8px 48px;
  display: flex;
  flex-direction: column;
}

.courses__step--1 {
  margin-bottom: 20px;
}

.courses__step--1,
.courses__step--2,
.courses__step--3 {
  display: flex;
  height: 100px;
  margin-top: 20px;
}

.courses__step--1 {
  height: 160px;
}

p > ul {
  margin-left: 20px;
}

p > ul > li {
  font-size: 14px;
}

.courses__step--1 > h2,
.courses__step--2 > h2,
.courses__step--3 > h2 {
  width: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  margin-right: 80px;
  margin-left: 10px;
}

.courses__step--1 > h2 > span,
.courses__step--2 > h2 > span,
.courses__step--3 > h2 > span {
  display: inline-block;
  margin-left: 12px;
  background-color: black;
  color: white;
  padding: 8px 12px;
  width: 40px !important;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  font-size: 30px;
  margin-top: 12px;
}

.step__description {
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  padding-left: 20px;
  border-left: 4px solid #192b90;
  width: 70%;
}

.courses__step--2 {
  height: 120px;
  margin-bottom: 20px;
}

.courses__step--3 {
  margin-bottom: 10px;
}

.step__description > p {
  color: #192b90;
  font-weight: 600;
}

.step__logos {
  display: flex;
}

.step__logo {
  border-radius: 100%;
  width: 48px;
  object-fit: cover;
  margin-right: 8px;
}

.steps__paypal--wrapper {
  width: 50px;
  height: 50px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 2px solid #192b90;
  overflow: hidden;
}

.steps__paypal--wrapper > img {
  margin: 0;
  margin-top: 20px;
  width: 124%;
  object-fit: contain;
}

.courses__steps--wrapper > p {
  font-size: 14px;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 8px;
  color: gray;
  text-align: center;
}

.step__description--span {
  margin-bottom: 8px;
  font-weight: 700;
  background-color: gold;
  padding: 0 8px;
  display: inline-block;
}

.enroll__btn {
  cursor: pointer;
  background-color: #192b90;
  width: 56%;
  font-size: 32px;
  font-weight: 700;
  color: white;
  border-radius: 8px;
  margin-top: 16px;
  transition: all 200ms ease;
  padding: 2px 12px;
}

.enroll__btn:hover {
  background-color: white;
  color: #192b90;
}

@media (max-width: 1024px) {
  .enroll__btn {
    width: 80%;
  }

  .course__img {
    object-fit: cover;
  }

  .payment__method > p {
    font-size: 16px;
    font-weight: 600;
  }

  .courses__schedule--container {
    width: 100%;
    padding-left: 20px;
  }

  .courses__schedule > p {
    font-size: 18px;
    padding-left: 12px;
  }

  .courses__schedule > h3 {
    margin-top: 20px;
    font-size: 28px;
  }

  .courses__payment--container > h3 {
    font-size: 28px;
  }

  .courses__payment--container {
    width: 52%;
  }

  .courses__payment-methods--wrapper {
    margin-left: 60px;
  }

  .courses__payment--container > h3 {
    padding-left: 80px;
  }
}

@media (max-width: 942px) {
  .subject__title {
    width: 62%;
  }
}

@media (max-width: 768px) {
  .course__title {
    font-size: 20px;
  }
  .subject__title {
    font-size: 24px;
    padding: 12px 32px 12px 60px;
    width: 60%;
  }

  .course__img--wrapper {
    flex: 0 1 auto;
    width: 100%;
    padding: 0% 20% 1% 20%;
    margin-top: 8px;
  }
  .course__description {
    width: 100%;
    flex: 0 1 auto;
    text-align: center;
    padding-bottom: 0;
  }

  .course__description > p {
    font-size: 20px;
  }
  .course__books {
    width: 100%;
  }

  .course__book {
    width: 25%;
    margin: 0px 4px;
  }

  .course__btn {
    font-size: 24px;
    margin-top: 20px;
  }

  .subject__title {
    width: 100%;
    border-radius: 0;
    padding: 12px 0;
    text-align: center;
  }

  .course__subjects--drop {
    padding-left: 60px;
    padding-right: 20px;
  }
  .close__btn {
    transform: translateX(-20px);
  }

  .courses__payment-schedule {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .courses__payment--container {
    width: 100%;
    text-align: center;
    padding: 0 40px;
  }

  .courses__payment--container > h3 {
    border-radius: 0;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .courses__payment-methods--wrapper {
    margin-left: 0;
    align-items: center;
    margin-bottom: 20px;
  }

  .courses__schedule {
    width: 100%;
    height: 440px;
  }

  .courses__schedule--descriptive {
    height: 300px;
  }

  .courses__schedule--container {
    padding: 0;
    padding-left: 20px;
    padding-top: 20px;
  }

  .schedule__item {
    margin: 20px 8px;
  }

  .courses__zoom--wrapper {
    width: 160px;
    height: 160px;
  }

  .courses__steps--container > h3 {
    font-size: 1.64em;
    margin-bottom: 8px;
  }
  .courses__step--1 > h2 > span,
  .courses__step--2 > h2 > span,
  .courses__step--3 > h2 > span {
    margin-top: 6px;
  }

  .courses__step--1,
  .courses__step--2,
  .courses__step--3 {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
  }

  .courses__step--1 {
    height: 220px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .courses__step--1 ul {
    text-align: left;
  }

  .step__description--span {
    margin-bottom: 8px;
    font-weight: 700;
    background-color: gold;
    padding: 0 8px;
    display: inline-block;
  }

  p > ul > li {
    font-size: 12px;
  }

  .courses__step--2 {
    height: 180px;
    margin-top: 0;
    margin-bottom: 12px;
  }

  .courses__step--3 {
    height: 140px;
  }

  .step__description {
    border: none;
    width: 86%;
    margin-top: 8px;
    text-align: center;
    padding-left: 0;
    align-items: center;
  }

  .step__logos {
    margin-top: 12px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
  }

  .step__logo {
    width: 40px;
    margin: 0 2px;
  }

  .courses__step--1 > h2,
  .courses__step--2 > h2,
  .courses__step--3 > h2 {
    border-bottom: 4px solid #192b90;
    width: 200px;
    padding-bottom: 4px;
    margin: 0 auto;
  }

  .enroll__btn {
    width: 100%;
    font-size: 24px;
  }

  .steps__paypal--wrapper {
    width: 48px;
    height: 48px;
    overflow: visible;
  }

  .steps__paypal--wrapper > img {
    margin: 0;
    width: 42px;
    height: 42px;
  }

  .courses__steps--wrapper {
    padding: 8px 0;
    margin-bottom: 20px;
  }

  .courses__steps--wrapper > p {
    padding: 0 20px;
    font-size: 12px;
  }

  .step__description > p {
    font-size: 14px;
    margin-top: 8px;
  }
}

@media (max-width: 400px) {
  .course__title {
    font-size: 17.2px;
  }

  .course__description > p {
    font-size: 15px;
  }

  .course__book {
    height: 120px;
  }

  .course__btn {
    font-size: 20px;
  }

  .courses__schedule--container {
    flex-direction: column;
    padding: 0;
  }

  .courses__schedule {
    height: 520px;
  }

  .courses__schedule--descriptive {
    height: auto;
  }

  .schedule__item {
    margin: 12px 0;
  }
  .courses__schedule--container > ul {
    padding-left: 16px;
    margin-top: 12px;
  }
  .span__hour {
    font-size: 18px;
  }

  .courses__zoom--wrapper {
    width: 100px;
    height: 100px;
    transform: translateY(-12px);
  }
  .courses__schedule > p {
    text-align: center;
    padding: 0;
  }

  .courses__schedule > h3 {
    font-size: 20px;
  }

  .courses__steps--container > h3 {
    font-size: 1.45em;
  }

  .courses__steps--container {
    padding: 12px;
  }

  .courses__step--1 > h2, .courses__step--2 > h2, .courses__step--3 > h2 {
    font-size: 32px;
  }

  .courses__step--1 > h2 > span, .courses__step--2 > h2 > span, .courses__step--3 > h2 > span {
    margin-left: 6px;
    width: 32px !important;
    height: 32px;
  }

  .step__logo {
    width: 32px;
  }

  .steps__paypal--wrapper {
    width: 32px;
    height: 32px;
  }

  .steps__paypal--wrapper > img {
    width: 24px;
  }
  .step__logo {
    margin: 0 2px;
  }
  .courses__steps--wrapper {
    margin: 8px 12px 20px 12px;
  }
  .courses__step--1 {
    height: 260px;
  }
}
