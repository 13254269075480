/* The side navigation menu */
.classroom__sidebar {
    margin: 0;
    padding: 0;
    width: 250px;
    background-color: #f1f1f1;
    position: fixed;
    height: 100%;
    overflow-y: scroll;
    padding-right: 4px;
  }

  .classroom__sidebar::-webkit-scrollbar {
    width: 4px;
    background-color: white;
  }

  .classroom__sidebar::-webkit-scrollbar-thumb {
    width: 0em;
    background-color: #182b90;
  }


  /* Sidebar links */
  .classroom__sidebar a {
    display: block;
    color: black;
    padding: 16px;
    text-decoration: none;
  }
  
  /* Active/current link */
  .classroom__sidebar a.active {
    background-color: #04AA6D;
    color: white;
  }
  
  /* Links on mouse-over */
  .classroom__sidebar a:hover:not(.active) {
    background-color: #555;
    color: white;
  }

  .classroom__logo:hover {
    background-color: #f1f1f1 !important;
  }
  
  
  /* Page content. The value of the margin-left property should match the value of the sidebar's width property */
  .classroom__content {
    margin-left: 250px;
    padding: 1px 16px;
    height: 100%;
  }

  .sidebar__class {
    padding: 8px 16px !important;
  }

  .sidebar__arrow {
    font-size: 24px !important;
  }

  .sidebar__home--btn {
    display: flex !important;
  }