/*

First Section

*/

.upload__class {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    height: 100px;
    justify-content: space-between;
    width: 600px;
    text-align: center;
}

.upload__class--btns {
    margin-top: 16px;
}

.upload__class--btns > button {
    padding: 8px 16px;
    margin: 0 12px;
}

.upload__btn {
    margin-top: 20px;
    width: 250px;
    font-size: 28px;
    padding: 12px 0;
    background-color: white;
}

.upload__btn:hover {
    color: white;
    background-color: #192b80;
}