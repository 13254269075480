nav {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 0 40px;
  background-color: white;
  position: relative;
  z-index: 2;
}

a {
  font-weight: 600;
  letter-spacing: 1px;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  width: 100%;
}

.img__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16%;
  height: 100px;
}

.nav__img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.nav__logo--2 {
  display: none;
}

.nav__links {
  width: 50%;
  display: flex;
  justify-content: space-around;
}

.nav__link {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 8px 16px;
  transition: color 200ms ease, background-color 200ms ease-in-out;
}

.nav__link:hover {
  color: white;
  background-color: #192b90;
}

.nav__link--btn {
  color: white;
  background-color: #192b90;
  padding: 8px 20px;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out, color 200ms ease;
  font-weight: 600;
}

.nav__link--btn:hover {
  background-color: #09082d;
  color: white;
}

.nav__backdrop {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #192b90;
  height: 200%;
  position: absolute;
  top: 20px;
  left: 20px;
  right: 20px;
  z-index: 10;
  opacity: 0;
  transform: translateX(100%);;
  transition: transform 200ms ease-in-out, opacity 120ms ease;
}

.active-menu {
  opacity: 1;
  transform: translateX(0);
}

@keyframes fadeIn {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.backdrop__close-icon {
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 32px !important;
    transition: all 200ms ease !important;
}

.backdrop__close-icon:hover {
    font-size: 38px !important;
}

.backdrop__links {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.backdrop__link {
    margin: 8px 0;
    padding: 2px;
}

.backdrop__link:hover {
    background-color: white;
    color: #192b90;
    font-weight: 700;
    text-decoration: underline;
}

.nav__classroom {
  display: flex;
}

#nav__icon--menu {
  margin-right: 32px;
  display: none;
  cursor: pointer;
  font-size: 36px !important;
}

.nav__link--backdrop {
  padding: 8px 24px;
  font-weight: 600;
  position: relative;
}

.nav__down-icon {
  width: 16px;
  height: 16px;
  margin-left: 8px;
  transition: filter 200ms ease, transform 200ms ease;
}

.nav__link:hover .nav__down-icon {
  filter: invert(1);
  transform: rotate(-180deg);
}

.nav__link--backdrop:hover .nav__courses--backdrop{
  opacity: 1;
  height: 120px;
  padding: 8px 16px;
}

.nav__courses--backdrop {
  position: absolute;
  top: 37.6px;
  overflow: hidden;
  background-color: white;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  opacity: 0;
  width: 100%;
  height: 0;
  padding: 0;
  transition: all 200ms ease;
}

.nav__backdrop--link--hover:hover {
  color: #192b90;
  transform: scaleX(1.02);
}

@media (max-width: 1024px) {
  nav {
    padding: 0 16px;
  }
}

@media (max-width: 860px) {
  #nav__icon--menu {
    display: block;
  }

  .nav__links {
    display: none;
  }
    
  .nav__img {
    display: none;
  }

  .img__wrapper {
    width: 120px;
    height: 100%;
  }

  .nav__logo--2 {
    display: block;
    width: 100%;
    min-width: 80px;
  }
}

@media (max-width: 480px)  {
    nav {
        padding: 0 12px;
    }

    .container {
        padding: 0 8px;
    }

    .img__wrapper {
      width: 100px;
    }

    .nav__link--btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px;
        font-size: 12px;
    }

    #nav__icon--menu {
        margin-right: 16px;
    }
}
