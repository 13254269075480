.course2__title::after {
  content: "con AutoCAD e Inventor";
}

.course__2--btn {
  margin-bottom: 20px;
}

.course__video-button {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
  padding-top: 20px;
}

.course__video-button--title {
  color: #192b90;
  font-weight: 800;
  font-size: 40px;
  letter-spacing: -1.5px;
  padding-bottom: 20px;
  text-align: center;
}

@media (max-width: 756px) {
  .course__video-button--title {
    font-size: 32px;
  }
}

@media (max-width: 400px) {
  .course__video-button--title {
    font-size: 26px;
  }
}
