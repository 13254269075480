.videoWrapper {
    position: relative;
    padding-bottom:  29%;
    overflow: hidden;
    width: 62%;
    border: 4px solid white;
}

.iframe {
    overflow: hidden;
    border: 0;
    align-self: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .videoWrapper {
        width: 80%;
        padding-bottom: 44%;
      }
}