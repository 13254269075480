.empty-video {
    margin-top: 24px;
    width: 640px;
    height: 360px;
    background-color: lightgray;
    outline: #192b80 2px solid;
    padding: 16px;
    display: flex;
    align-items: center;
}

.empty-video__icon {
    color: white;
    font-size: 80px !important;
    margin-right: 20px;
}