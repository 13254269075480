.user__edit--container--icon {
    font-size: 24px !important;
    color: black;
  }

  .admin__user--edit {
    display: flex;
    align-items: center;
    justify-content: right;
    cursor: pointer;
    margin-top: -8px;
    margin-bottom: 4px;
  }

  .user__edit--btns {
    margin-top: 16px;
    display: flex;
  }