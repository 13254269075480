.whatsapp__logo--wrapper {
  cursor: pointer;
  width: 80px;
  height: 80px;
  position: fixed;
  right: 40px;
  bottom: 32px;
  background-color: #25d366;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  transition: transform 200ms ease;
  overflow: hidden;
  z-index: 99;
}

.whatsapp__img {
  width: 70%;
  height: 70%;
}

.whatsapp__logo--wrapper:hover {
  overflow: visible;
}

.whatsapp__logo--wrapper:hover .whatsapp__img {
  transform: scale(1.07);
}

.whatsapp__logo--wrapper:hover .whatsapp__backdrop {
  opacity: 1;
  left: -200px;
}

.whatsapp__backdrop {
  font-weight: 600;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  position: absolute;
  left: -150px;
  width: 220px;
  color: white;
  background-color: #25d366;
  padding: 8px 12px;
  z-index: -1;
  opacity: 0;
  transition: opacity 200ms ease, left 300ms ease;
}

@media (max-width: 480px) {
  .whatsapp__logo--wrapper {
    right: 20px;
    width: 52px;
    height: 52px;
  }

  .whatsapp__backdrop {
    left: -170px;
  }
}
