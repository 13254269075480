.maintenance__bg {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.maintenance__container {
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 20px 40px;
  border: 2px solid #192b90;
  text-align: center;
}

.maintenance__container > h3 {
  font-size: 36px;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.maintenance__container > p {
  margin-bottom: 8px;
}

.maintenance__icon {
  font-size: 36px !important;
  color: black;
}

.maintenance__social {
  color: black !important;
  margin: 0 8px;
}

.maintenance__social:hover {
  color: #192b90 !important;
}

.socials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;
}

.maintenance__btn {
    cursor:pointer;
  margin-top: 12px;
}

@media (max-width: 480px) {
  .maintenance__container > h3 {
    font-size: 24px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  .maintenance__icon {
  font-size: 24px !important;
  color: black;
}

.maintenance__container > p {
  font-size: 16px;
}

.maintenance__social {
  font-size: 40px !important;
}


  .maintenance__btn {
    display: inline;
  }
}
