#contact {
  height: 100%;
  display: flex;
  color: white;
  padding: 40px 0;
  position: relative;
  background: linear-gradient(
    to right,
    rgba(36, 156, 184, 1),
    rgba(36, 156, 184, 1) 40%,
    rgba(36, 156, 184, 0)
  );
}

.contact__background--wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  background-color: #249cb8;
  overflow: hidden;
}

.contact__background {
  position: absolute;
  left: 200px;
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
  object-fit: cover;
  z-index: 0;
  filter: opacity(0.4);
}

.contact__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  padding: 0 20px;
  z-index: 1;
}

.contact__header {
  display: flex;
  flex-direction: column;
}

.contact__form-section {
  display: flex;
  width: 100%;
  align-items: center;
  position: relative;
  justify-content: left;
}

.contact__form {
  width: 50%;
  padding: 20px 20px;
}

.contact__img--wrapper {
  border-radius: 99999px;
  width: 250px;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  margin-top: 80px;
  margin-left: 80px;
  box-shadow: 4px 6px 12px 1.5px rgba(25, 43, 144, 0.72);
}

.contact__img {
  width: 105%;
  transform: scaleX(1);
  object-fit: cover;
}

form > div {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 8px 0;
}

form > div > label {
  margin-bottom: 4px;
}

form > div > input {
  padding: 4px 8px;
  border: 2px solid white;
}

form > div > input:hover {
  border-top: 2px solid white;
  border-bottom: #192b90 2px solid;
}

form > div > input:focus {
  outline: none;
  border-bottom: #249cb8 2px solid;
}

.contact__header > h3 {
  font-size: 40px;
}

.contact__header > h4 {
  font-size: 24px;
}

.contact__header > p {
  font-size: 16px;
  margin-top: 12px;
}

.contact__btn {
  cursor: pointer;
  color: white;
  background-color: #192b90;
  padding: 12px 60px;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out, color 200ms ease;
  font-weight: 600;
  font-size: 14px;
  margin-top: 20px;
}

.contact__btn:hover {
  background-color: #09082d;
  color: white;
}

.contact__form-section > p {
  position: absolute;
  left: 59.5%;
  top: 15%;
  max-width: 200px;
  font-size: 18px;
  font-weight: 600;
}

.form__text-area {
  padding: 8px;
  outline: none;
  font-size: 14px;
}

label > span {
  color: red;
}

.form-sended {
  background-color: rgb(47, 255, 64);
}

.form-sended:hover {
  background-color: green;
}

@media (max-width: 768px) {
  .contact__header > h3 {
    font-size: 32px;
  }
  .contact__header > h4{ 
    font-size: 20px;
  }

  .contact__header > p {
    font-size: 14px;
  }

  .contact__img--wrapper {
    margin-left: auto;
  }
}
@media (max-width: 586px) {
    .contact__form-section {
        flex-direction: column;
    }
    .contact__form {
        width: 90%;
    }
    .contact__form-section > p {
        bottom: 272px;
        left: 50%;
        transform: translate(-50%);
        top: auto;
        width: 200px;
    }

    .contact__img--wrapper {
        margin: 0 auto;
        margin-top: 120px;
    }
}

@media (max-width: 400px) {
    .contact__header > h3 {
        font-size: 28px;
    }

    .contact__header > h4 {
        font-size: 16px;
    }

    .contact__form {
        padding-top: 8px;
    }

    .contact__btn {
        padding: 12px 32px;
    }
}