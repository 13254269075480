@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}

a {
  text-decoration: none;
  color: black;
}

.row {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
}
